import { Routes, Route } from "react-router-dom";
import { Toaster } from "@/components/ui/sonner"
import { AuthScreen } from '@/screens/auth/';
import { RegisterForm } from "@/screens/auth/register-form";
import { LoginForm } from "@/screens/auth/login-form";
import { AuthProvider } from "@/providers/auth-provider";
import Layout from "@/components/layout";
import ProfileLayout from "@/screens/profile/layout";
import SettingsScreen from "@/screens/profile/settings";
import { BillingScreen } from "@/screens/profile/billing";
import DashboardScreen from "@/screens/dashboard";
import { ReportsScreen } from "@/screens/reports";
import ReportScreen from "@/screens/reports/form";
import { StateProvider } from "@/providers/state-provider";
import { PropertyStage } from "@/screens/reports/form/property";
import { ReportTypeStage } from "@/screens/reports/form/report-type";
import { ImageUploadStage } from "@/screens/reports/form/images";
import { SummaryStage } from "@/screens/reports/form/summary";
import { BaseQuestionsForm } from "@/screens/reports/form/base-questions";
import { FinalDetailsStage } from "@/screens/reports/form/final-details";
import MakeSafeFormWizardProvider from "@/screens/reports/makesafe";
import { MakeSafeBaseQuestionsForm } from "@/screens/reports/makesafe/base-questions";
import { MakeSafeImageUploadStage } from "@/screens/reports/makesafe/images";

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route element={<AuthScreen />}>
          <Route index element={<LoginForm />} />
          <Route path="/register" element={<RegisterForm />} />
        </Route>
        <Route path="/v1/" element={<StateProvider><Layout /></StateProvider>}>
          <Route index element={<DashboardScreen />} />
          <Route path="/v1/reports" element={<ReportsScreen />} />

          <Route path="/v1/reports/:id" element={<ReportScreen />} >
            <Route path="client" element={<PropertyStage />} />
            <Route path="type" element={<ReportTypeStage />} />
            <Route path="images" element={<ImageUploadStage />} />
            <Route path="summary" element={<SummaryStage />} />
            <Route path="base" element={<BaseQuestionsForm />} />
            <Route path="final" element={<FinalDetailsStage />} />
          </Route>

          <Route path="/v1/reports/makesafe/:id" element={<MakeSafeFormWizardProvider />} >
            <Route path="client" element={<PropertyStage />} />
            <Route path="images" element={<MakeSafeImageUploadStage />} />
            <Route path="summary" element={<SummaryStage />} />
            <Route path="base" element={<MakeSafeBaseQuestionsForm />} />
            <Route path="final" element={<FinalDetailsStage />} />
          </Route>

          <Route path="/v1/profile" element={<ProfileLayout />} >
            <Route index element={<SettingsScreen />} />
            <Route path="/v1/profile/billing" element={<BillingScreen />} />
          </Route>
        </Route>
        {/* <Route path="/home" element={<Layout />}>
          <Route index element={<Auth />} />
          <Route path="privacy" element={<Privacy />} />
        </Route> */}
      </Routes>
      <Toaster />
    </AuthProvider>
  );
}

export default App;