import { postApi } from "@/lib/fetch";
import { db, storage } from "@/lib/firebase";
import { reportTypeQuestions } from "@/screens/reports/data/data";
import { doc, getDoc, increment, updateDoc } from "firebase/firestore";
import { ref, uploadBytes } from "firebase/storage";
import { toast } from "sonner";

const html2pdf = require("html2pdf.js");

let tid: any;

export const buildReport = async (id: string, user: any) => {
  try {
    tid = toast.loading("Generating Report...", {
      duration: Infinity,
    });

    const d = await getDoc(doc(db, `Reports/${id}`));
    
    if (!d.exists()) return null;

    const data = d.data();
    console.log("data for generating pdf file",data)
    //if (data.pdf) return null;
console.log("data.reportTemplate =====>",data.reportTemplate)
    let output = data.reportTemplate === "makesafe" ? makesafeHtml : html;
console.log("output====>",output)
    Object.keys(data).forEach((key: string) => {
      const value =
        typeof data[key] === "string"
          ? String(data[key]).split("\n").join("<br/>")
          : data[key];
      output = output.split(`{{${key}}}`).join(value);
    });

    Object.keys(user).forEach((key: string) => {
      const value =
        typeof data[key] === "string"
          ? String(data[key]).split("\n").join("<br/>")
          : data[key];
      output = output.split(`{{${key}}}`).join(value);
    });

    const siteAddress = data.autocomplete_address
      ? data.autocomplete_address
      : (data.site_address1 ? data.site_address1 + ", " : "") +
        (data.site_address2 ? data.site_address2 + ", " : "") +
        (data.site_city ? data.site_city + ", " : "") +
        (data.site_state ? data.site_state + ", " : "") +
        data.site_postcode;
    const companyAddress =
      (user.address1 ? user.address1 + ", " : "") +
      (user.address2 ? user.address2 + ", " : "") +
      (user.city ? user.city + ", " : "") +
      (user.state ? user.state + ", " : "") +
      (user.post_code ? user.post_code : "");

    const timeArr = data?.inspection_time?.split(":");
    const hours = timeArr.length === 2 ? timeArr[0] : 0;
    const meridiem = hours > 11 ? "PM" : "AM";

    const today = new Date();

    const reportTypeTitle = data.report_type
      ? data.report_type.charAt(0).toUpperCase() + data.report_type.slice(1)
      : data.reportTemplate.charAt(0).toUpperCase() +
        data.reportTemplate.slice(1);
    output = output.replace("{{report_type_title}}", reportTypeTitle);
    output = output.replace("{{site_address}}", siteAddress);
    output = output.replace(
      "{{todays_date}}",
      `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(today.getDate()).padStart(2, "0")}`
    );
    output = output.replace(
      "{{limitations}}",
      user?.limitations ? user.limitations : limitations
    );
    output = output.replace("{{meridiem}}", meridiem);

    // report-based questions
    const extraqs = reportTypeQuestions[data?.report_type]
      ?.map((qs) => {
        return `<div class="col-span-2 p-2 border-b border-[#F1F5F9]"><b>${
          qs.label
        }</b></div><div class="col-span-3 p-2 border-b border-[#F1F5F9]">${
          data[qs.id] || ""
        }</div>`;
      })
      .join("");

    output = output.replace("{{report_type_questions}}", extraqs);
console.log("data.?images",data,data?.images)
    let imgs = "";
    for await (const img of data?.images) {
      const res = await postApi("/reports/image", {
        image: img.url,
      });
      imgs =
        imgs +
        `<img src="data:${res.headers["content-type"]};base64,${res.data}" class="h-48 w-auto max-w-full" />`;
    }

    const logo = user?.profileImage
      ? await postApi("/reports/image", {
          image: user.profileImage,
        })
      : null;

    output = output.replace("{{report_images}}", imgs);

    html2pdf()
      .from(output)
      .set({
        margin: [25, 10, 12, 10],
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 1, scrollY: 0, logging: true },
        jsPDF: { unit: "mm", format: "a4", orientation: "p" },
        pagebreak: {
          before: ".page-break",
          avoid: ["img", "div"],
          mode: ["avoid-all", "css", "legacy"],
        },
      })
      .toPdf()
      .get("pdf")
      .then(async function (pdf: any) {
        try {
          toast.loading("Applying company data...", {
            id: tid,
            duration: Infinity,
          });
          var totalPages = pdf.internal.getNumberOfPages();
          for (var i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(10);
            pdf.setTextColor(100);
            pdf.text(
              "Page | " + i,
              pdf.internal.pageSize.getWidth() - 25,
              pdf.internal.pageSize.getHeight() - 6
            );

            pdf.text(
              user?.company_name || "",
              pdf.internal.pageSize.getWidth() - 10,
              8,
              {
                align: "right",
              }
            );

            pdf.text(
              `A | ${companyAddress}`,
              pdf.internal.pageSize.getWidth() - 10,
              13,
              {
                align: "right",
              }
            );
            pdf.text(
              `E | ${user?.email || ""}`,
              pdf.internal.pageSize.getWidth() - 10,
              18,
              {
                align: "right",
              }
            );

            pdf.text(
              user?.company_name || "",
              pdf.internal.pageSize.getWidth() / 2,
              pdf.internal.pageSize.getHeight() - 6,
              {
                align: "center",
              }
            );

            if (logo?.data) {
              await new Promise((resolve) => {
                const imageType = String(
                  logo.headers["content-type"].split("/")[1]
                );
                const img = new Image();
                img.onload = () => {
                  const ratio = img.width / img.height;
                  const height = 20 * ratio;
                  const width = 20;
                  pdf.addImage(
                    img,
                    `${imageType.toUpperCase()}`,
                    10,
                    4,
                    height,
                    width,
                    "logo",
                    "NONE",
                    0
                  );
                  resolve(true);
                };
                img.src = `data:${logo.headers["content-type"]};base64,${logo.data}`;
              });
            }
          }
        } catch (e) {
          console.log(e);
        }
      })
      .output("blob")
      .then(async (blob: Blob) => {
        toast.loading("Saving Report...", {
          id: tid,
          duration: 4000,
        });

        try {
          await uploadBytes(ref(storage, `reports/${id}/report.pdf`), blob);
          await updateDoc(doc(db, `Reports/${id}`), {
            pdf: `reports/${id}/report.pdf`,
          });
          await updateDoc(doc(db, `Users/${user?.id}`), {
            reportCount: increment(1),
          });
        } catch (e) {
          console.log(e);
          throw new Error("Unable to save PDF");
        }
      })
      .save()
      .then(() => {
        toast.success("Report Generated", {
          id: tid,
          duration: 3000,
        });
      });
  } catch (e) {
    console.log(e);
    toast.error("Unable to generate", {
      id: tid,
      duration: 4000,
      description: "Please try again.",
    });
  }
};

const html = `
<html>
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <script src="https://cdn.tailwindcss.com"></script>
        <script>
            tailwind.config = {
                theme: {
                    fontSize: {
                        sm: '0.65rem',
                        base: '0.8rem',
                        xl: '1.25rem',
                        '2xl': '1.563rem',
                        '3xl': '1.953rem',
                        '4xl': '2.441rem',
                        '5xl': '3.052rem',
                    }
                }
            }
        </script>
    </head>
    
    <body id="content" class="flex flex-col justify-between h-full">

        <div id="page" class="flex flex-col space-y-6 w-full">
            <h1 class="text-center text-2xl font-bold">{{report_type_title}} Report</h1>

            <table class="table-auto text-sm">
                <tbody>
                    <tr class="border">
                        <td class="border p-1.5"><b>Job Number</b></td>
                        <td class="border p-1.5">{{job_number}}</td>
                        <td class="border p-1.5"><b>Date</b></td>
                        <td class="border p-1.5">{{todays_date}}</td>
                    </tr>
                    <tr class="border">
                        <td class="border p-1.5"><b>Client Reference</b></td>
                        <td class="border p-1.5">{{client_reference}}</td>
                        <td class="border p-1.5"><b>Report By</b></td>
                        <td class="border p-1.5">{{inspection_by}}</td>
                    </tr>
                    <tr class="border">
                        <td class="border p-1.5"><b>Property Representative name</b></td>
                        <td class="border p-1.5">{{customer_name}}</td>
                        <td class="border p-1.5"><b>Attendance Date</b></td>
                        <td class="border p-1.5">{{inspection_date}}</td>
                    </tr>
                    <tr class="border">
                        <td class="border p-1.5"><b>Site Address</b></td>
                        <td class="border p-1.5" colspan="3">{{site_address}}</td>
                    </tr>
                </tbody>
            </table>

            <div class="grid grid-cols-5 w-full text-sm">
                <div class="col-span-5 bg-[#d0d5db] font-bold h-10 px-2">Property Information</div>
                <div class="col-span-2 p-2 border-b border-[#F1F5F9] h-full">
                    <b>Construction Type</b>
                </div>
                <div class="col-span-3 p-2 border-b border-[#F1F5F9]">
                    {{construction_type}}
                </div>
                <div class="col-span-2 p-2 border-b border-[#F1F5F9]">
                    <b>Height</b>
                </div>
                <div class="col-span-3 p-2 border-b border-[#F1F5F9]">
                    {{property_height}}
                </div>
                <div class="col-span-2 p-2 border-b border-[#F1F5F9]">
                    <b>Property Condition</b>
                </div>
                <div class="col-span-3 p-2 border-b border-[#F1F5F9]">
                    {{property_condition}}
                </div>
                <div class="col-span-2 p-2 border-b border-[#F1F5F9]">
                    <b>Roof Type</b>
                </div>
                <div class="col-span-3 p-2 border-b border-[#F1F5F9]">
                    {{roof_material}}
                </div>
                <div class="col-span-2 p-2 border-b border-[#F1F5F9]">
                    <b>Roof Condition</b>
                </div>
                <div class="col-span-3 p-2 border-b border-[#F1F5F9]">
                    {{roof_condition}}
                </div>
                <div class="col-span-2 p-2 border-b border-[#F1F5F9]">
                    <b>Age of Property</b>
                </div>
                <div class="col-span-3 p-2 border-b border-[#F1F5F9]">
                    {{property_age}}
                </div>
            </div>

            <div class="grid grid-cols-5 w-full text-sm">
                <div class="col-span-5 bg-[#d0d5db] font-bold h-10 px-2">Inspection Information</div>
                <div class="col-span-2 p-2 border-b border-[#F1F5F9]">
                    <b>Date of Inspection</b>
                </div>
                <div class="col-span-3 p-2 border-b border-[#F1F5F9]">
                    {{inspection_date}}
                </div>
                <div class="col-span-2 p-2 border-b border-[#F1F5F9]">
                    <b>Time of Inspection</b>
                </div>
                <div class="col-span-3 p-2 border-b border-[#F1F5F9]">
                    {{inspection_time}} {{meridiem}}
                </div>
                <div class="col-span-2 p-2 border-b border-[#F1F5F9]">
                    <b>Inspection By</b>
                </div>
                <div class="col-span-3 p-2 border-b border-[#F1F5F9]">
                    {{inspection_by}}
                </div>
                <div class="col-span-2 p-2 border-b border-[#F1F5F9]">
                    <b>Insured owner in attendance</b>
                </div>
                <div class="col-span-3 p-2 border-b border-[#F1F5F9]">
                    {{owner_in_attendance}}
                </div>
                <div class="col-span-2 p-2 border-b border-[#F1F5F9]">
                    <b>Weather at Time of Inspection</b>
                </div>
                <div class="col-span-3 p-2 border-b border-[#F1F5F9]">
                    {{weather}}
                </div>
            </div>

            <div class="grid grid-cols-5 w-full text-sm">
                <div class="col-span-5 bg-[#d0d5db] font-bold h-10 px-2">Report Details</div>
                <div class="col-span-2 p-2 border-b border-[#F1F5F9]">
                    <b>Claim Circumstances</b>
                </div>
                <div class="col-span-3 p-2 border-b border-[#F1F5F9]">
                    {{claim_circumstances}}
                </div>
                <div class="col-span-2 p-2 border-b border-[#F1F5F9]">
                    <b>Resultant Damage</b>
                </div>
                <div class="col-span-3 p-2 border-b border-[#F1F5F9]">
                    {{resultant_damages}}
                </div>
                <div class="col-span-2 p-2 border-b border-[#F1F5F9]">
                    <b>Recommended Insurable Repairs</b>
                </div>
                <div class="col-span-3 p-2 border-b border-[#F1F5F9]">
                    {{detailed_scope}}
                </div>
                <div class="col-span-2 p-2 border-b border-[#F1F5F9]">
                    <b>Maintenance Issues Present</b>
                </div>
                <div class="col-span-3 p-2 border-b border-[#F1F5F9]">
                    {{maintenance_issues}}
                </div>
            </div>

            <div class="grid grid-cols-5 w-full text-sm">
                {{report_type_questions}}
            </div>
            
            <div class="grid grid-cols-2 w-full text-sm gap-4">
                <div class="col-span-2 bg-[#d0d5db] font-bold h-10 px-2">Photo Schedule</div>
                {{report_images}}
            </div>

            <div class="grid grid-cols-5 w-full text-sm page-break pb-6">
                <div class="col-span-5 bg-[#d0d5db] font-bold h-10 px-2">Limitations</div>
                <div class="col-span-5 text-[0.7rem]">{{limitations}}</div>
            </div>
            
        </div>
    </body>
</html>
`;

const makesafeHtml = `
<html>
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <script src="https://cdn.tailwindcss.com"></script>
        <script>
            tailwind.config = {
                theme: {
                    fontSize: {
                        sm: '0.65rem',
                        base: '0.8rem',
                        xl: '1.25rem',
                        '2xl': '1.563rem',
                        '3xl': '1.953rem',
                        '4xl': '2.441rem',
                        '5xl': '3.052rem',
                    }
                }
            }
        </script>
    </head>
    
    <body id="content" class="flex flex-col justify-between h-full">

        <div id="page" class="flex flex-col space-y-6 w-full">
            <h1 class="text-center text-2xl font-bold">Make Safe Report</h1>

            <table class="table-auto text-sm">
                <tbody>
                    <tr class="border">
                        <td class="border p-1.5"><b>Job Number</b></td>
                        <td class="border p-1.5">{{job_number}}</td>
                        <td class="border p-1.5"><b>Date</b></td>
                        <td class="border p-1.5">{{todays_date}}</td>
                    </tr>
                    <tr class="border">
                        <td class="border p-1.5"><b>Client Reference</b></td>
                        <td class="border p-1.5">{{client_reference}}</td>
                        <td class="border p-1.5"><b>Report By</b></td>
                        <td class="border p-1.5">{{inspection_by}}</td>
                    </tr>
                    <tr class="border">
                        <td class="border p-1.5"><b>Representative name</b></td>
                        <td class="border p-1.5">{{customer_name}}</td>
                        <td class="border p-1.5"></td>
                        <td class="border p-1.5"></td>
                    </tr>
                    <tr class="border">
                        <td class="border p-1.5"><b>Site Address</b></td>
                        <td class="border p-1.5" colspan="3">{{site_address}}</td>
                    </tr>
                </tbody>
            </table>

            <div class="grid grid-cols-5 w-full text-sm">
                <div class="col-span-5 bg-[#d0d5db] font-bold h-10 px-2">Make Safe Report</div>
                <div class="col-span-2 p-2 border-b border-[#F1F5F9] h-full">
                    <b>Date Completed</b>
                </div>
                <div class="col-span-3 p-2 border-b border-[#F1F5F9]">
                    {{inspection_date}}
                </div>
                <div class="col-span-2 p-2 border-b border-[#F1F5F9]">
                    <b>Time of Inspection</b>
                </div>
                <div class="col-span-3 p-2 border-b border-[#F1F5F9]">
                    {{inspection_time}} {{meridiem}}
                </div>
                <div class="col-span-2 p-2 border-b border-[#F1F5F9]">
                    <b>Weather at time of inspection</b>
                </div>
                <div class="col-span-3 p-2 border-b border-[#F1F5F9]">
                    {{weather}}
                </div>
                <div class="col-span-2 p-2 border-b border-[#F1F5F9]">
                    <b>Insured owner in attendance</b>
                </div>
                <div class="col-span-3 p-2 border-b border-[#F1F5F9]">
                    {{owner_in_attendance}}
                </div>
                <div class="col-span-2 p-2 border-b border-[#F1F5F9]">
                    <b>Number of trades that attended</b>
                </div>
                <div class="col-span-3 p-2 border-b border-[#F1F5F9]">
                    {{trades_that_attended}}
                </div>
                <div class="col-span-2 p-2 border-b border-[#F1F5F9]">
                    <b>Materials used / Cost of materials</b>
                </div>
                <div class="col-span-3 p-2 border-b border-[#F1F5F9]">
                    {{materials_used}}
                </div>
                <div class="col-span-2 p-2 border-b border-[#F1F5F9]">
                    <b>How long were trades on site</b>
                </div>
                <div class="col-span-3 p-2 border-b border-[#F1F5F9]">
                    {{trades_on_site}}
                </div>
                <div class="col-span-2 p-2 border-b border-[#F1F5F9]">
                    <b>PO Instructions</b>
                </div>
                <div class="col-span-3 p-2 border-b border-[#F1F5F9]">
                    {{po_instructions}}
                </div>
                <div class="col-span-2 p-2 border-b border-[#F1F5F9]">
                    <b>Claim Circumstances</b>
                </div>
                <div class="col-span-3 p-2 border-b border-[#F1F5F9]">
                    {{claim_circumstances}}
                </div>
                <div class="col-span-2 p-2 border-b border-[#F1F5F9]">
                    <b>Cause</b>
                </div>
                <div class="col-span-3 p-2 border-b border-[#F1F5F9]">
                    {{cause}}
                </div>
                <div class="col-span-2 p-2 border-b border-[#F1F5F9]">
                    <b>Damage</b>
                </div>
                <div class="col-span-3 p-2 border-b border-[#F1F5F9]">
                    {{damage}}
                </div>
                <div class="col-span-2 p-2 border-b border-[#F1F5F9]">
                    <b>Works completed</b>
                </div>
                <div class="col-span-3 p-2 border-b border-[#F1F5F9]">
                    {{works_completed}}
                </div>
                <div class="col-span-2 p-2 border-b border-[#F1F5F9]">
                    <b>Works remaining</b>
                </div>
                <div class="col-span-3 p-2 border-b border-[#F1F5F9]">
                    {{works_remaining}}
                </div>
            </div>

            <div class="grid grid-cols-2 w-full text-sm gap-4">
                <div class="col-span-2 bg-[#d0d5db] font-bold h-10 px-2">Photo Schedule</div>
                {{report_images}}
            </div>

            <div class="grid grid-cols-5 w-full text-sm page-break pb-6">
                <div class="col-span-5 bg-[#d0d5db] font-bold h-10 px-2">Limitations</div>
                <div class="col-span-5 text-[0.7rem]">{{limitations}}</div>
            </div>
            
        </div>
    </body>
</html>
`;

const limitations = `
<ol class='w-full'>
<li>1. The Inspector will conduct a non-invasive visual inspection which will be limited to those accessible areas and sections of the property to which Safe and Reasonable Access (see Definitions below) is both available and permitted on the date and time of the inspection. Areas where reasonable entry is denied to the inspector, or where safe and reasonable access is not available, are excluded from and do not form part of, the inspection. Those areas may be the subject of an additional inspection upon request following the provision or reasonable entry and access.</li>
<li>2. The Inspection WILL NOT involve any invasive inspection including cutting, breaking apart, dismantling, removing or moving objects including, but not limited to, roofing, wall and ceiling sheeting, ducting, foliage, mouldings, debris, roof insulation, sarking, sisalation, floor or wall coverings, sidings, fixtures, floors, pavers, furnishings, appliances or personal possessions.</li>
<li>3. The Inspection and Report compares the inspected building with a building that was constructed in accordance with the generally accepted practice at the time of construction and which has been maintained such that there has been no significant loss of strength and durability.</li>
<li>4. The Inspection excludes the inside of walls, between floors, inside skillion roofing, inside the eaves, behind stored goods in cupboards, and other areas that are concealed or obstructed. The inspector WILL NOT dig, gouge, force or perform any other invasive procedures.</li>
<li>5. The Report is not a certificate of compliance that the property complies with the requirements of any Act, regulation, ordinance, local law or by-law, or as a warranty or an insurance policy against problems developing with the building in the future.</li>
<li>6. The Inspection WILL NOT look for or report on Timber Pest Activity.</li>
<li>7. ASBESTOS: No inspection for asbestos will be carried out at the property and no report on the presence or absence of asbestos will be provided. If during the course of the Inspection asbestos or materials containing asbestos happened to be noticed then this may be noted in the general remarks section of the report. If asbestos is noted as present within the property then you agree to seek advice from a qualified asbestos removal expert as to the amount and importance of the asbestos present and the cost sealing or of removal.</li>
<li>8. MOULD (MILDEW) AND NON-WOOD DECAY FUNGI DISCLAIMER: No inspection or report will be made for Mould (Mildew) and non-wood decay fungi.</li>
<li>9. ESTIMATING DISCLAIMER: Any estimates provided in the Report are merely opinions of possible costs that could be encountered, based on the knowledge and experience of the inspector, and are not estimates in the sense of being a calculation of the likely costs to be incurred.</li>
<li>10. If the property to be inspected is occupied then You must be aware that furnishings or household items may be concealing evidence of problems, which may only be revealed when the items are moved or removed. Where the Report says the property is occupied You agree to:</li>
    <ol>
        <li>1. Obtain a statement from the owner as to;</li>
            <ol><li>1. any Timber Pest activity or damage;</li>
                <li>2. timber repairs or other repairs</li>
                <li>3. alterations or other problems to the property known to them</li>
                <li>4. any other work carried out to the property including Timber Pest treatments</li>
                <li>5. obtain copies of any paperwork issued and the details of all work carried out</li>
            </ol>
        <li>2. Indemnify the Inspector from any loss incurred by You relate to the items listed in clause a) above where no such statement is obtained.</li>
    </ol>
<li>11. The Inspection and Report WILL NOT report on any defects which may not be apparent due to prevailing weather conditions at the time of the inspection. Such defects may only become apparent in differing weather conditions.</li>
<li>12. You agree that We cannot accept any liability for Our failure to report a defect that was concealed by the owner of the building being inspected and You agree to indemnify Us for any failure to find such concealed defects.</li>
</ol>
`;
