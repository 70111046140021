"use client";

import * as React from "react";

import { cn } from "@/lib/utils";
import { Icons } from "@/components/icons";

import { useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { UserAuthFormProps } from "@/screens/auth/register-form";
import { Link, useNavigate } from "react-router-dom";
import {
  GoogleAuthProvider,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { auth, formatAuthError } from "@/lib/firebase";
import { toast } from "sonner";

const loginFormSchema = z.object({
  email: z
    .string()
    .min(5, {
      message: "Please enter your full email address",
    })
    .email("This is not a valid email."),
  password: z
    .string({
      required_error: "Please enter a password",
    })
    .min(8, {
      message: "Passwords must be at least 8 characters",
    }),
});

type LoginFormValues = z.infer<typeof loginFormSchema>;

// This can come from your database or API.
const defaultValues: Partial<LoginFormValues> = {
  email: "",
  password: "",
};

export function LoginForm({ className, ...props }: UserAuthFormProps) {
  const navigate = useNavigate();

  const form = useForm<LoginFormValues>({
    resolver: zodResolver(loginFormSchema),
    defaultValues,
    mode: "onChange",
  });

  const [isLoading, setLoading] = React.useState<boolean>(false);

  async function onSubmit(data: LoginFormValues) {
    try {
      setLoading(true);

      const { user: userCredentials } = await signInWithEmailAndPassword(
        auth,
        data.email,
        data.password
      );

      setLoading(false);
      if (userCredentials && !userCredentials.emailVerified) {
        sendEmailVerification(userCredentials);
        toast.info("Email verification resent.");
      } else {
        navigate("/v1/");
      }
    } catch (e: unknown) {
      const msg = e instanceof Error ? e.message : "";
      toast.error(formatAuthError(msg), {
        description: "Please try again.",
      });
      setLoading(false);
    }
  }

  const googleLogin = async () => {
    try {
      setLoading(true);
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);

      const credential = GoogleAuthProvider.credentialFromResult(result);
      if (credential) {
        setLoading(false);
      } else {
        throw new Error("Unable to connect with Google.");
      }
    } catch (e: unknown) {
      const msg = e instanceof Error ? e.message : "";
      toast.error(formatAuthError(msg), {
        description: "Please try again.",
      });
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex flex-col space-y-2 text-center">
        <h1 className="text-2xl font-semibold tracking-tight">
          Login to Reportable
        </h1>
        <p className="text-sm text-muted-foreground">
          or{" "}
          <Link to="/register" className="underline text-primary">
            Create an Account
          </Link>
        </p>
      </div>
      <div className={cn("grid gap-6", className)} {...props}>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="grid gap-2">
              <div className="grid gap-1">
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          type="email"
                          placeholder="name@example.com"
                          autoCapitalize="none"
                          autoComplete="email"
                          autoCorrect="off"
                          disabled={isLoading}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="grid gap-1">
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          type="password"
                          placeholder="Password"
                          disabled={isLoading}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <Button type="submit" size="lg" disabled={isLoading}>
                {isLoading && (
                  <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
                )}
                Sign In with Email
              </Button>
            </div>
          </form>
          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <span className="w-full border-t" />
            </div>
            <div className="relative flex justify-center text-xs uppercase">
              <span className="bg-background px-2 text-muted-foreground">
                Or continue with
              </span>
            </div>
          </div>
          <Button
            onClick={googleLogin}
            variant="outline"
            size="lg"
            type="button"
            disabled={isLoading}
          >
            {isLoading ? (
              <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
            ) : (
              <Icons.google className="mr-2 h-4 w-4" />
            )}{" "}
            Google
          </Button>
        </Form>
      </div>
    </>
  );
}
