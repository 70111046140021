"use client"

import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { useFormWizard } from "@/screens/reports/form"
import { FormActions } from "@/screens/reports/form/form-actions";
import { Input } from "@/components/ui/input";
import { useDropzone } from "react-dropzone";
import { XIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { ImageType, uploadBlobs } from "@/lib/upload";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import { postApi } from "@/lib/fetch";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { app, db } from "@/lib/firebase";
import { Icons } from "@/components/icons";
import { doc, updateDoc } from "firebase/firestore";

export const MakeSafeImageUploadStage = () => {

    const { id } = useParams();
    const { goNext, report, loading, setLoading } = useFormWizard();
    
    const [previews, setPreviews] = useState<ImageType[]>([])
    const [blobs, setBlobs] = useState<Blob[]>([])

    const { acceptedFiles, getRootProps, getInputProps, open } = useDropzone({ 
        maxFiles: 20, 
        multiple: true,
        maxSize: 5000000,
        noClick: true,
        noKeyboard: true,
        accept: {
            'image/png': [".png"],
            'image/jpeg': [".jpeg", ".jpg"],
            'image/webp': [".webp"]
        },
        onDrop: acceptedFiles => {
            setPreviews([
                ...previews,
                ...acceptedFiles.map(file => {
                    return {
                        url: URL.createObjectURL(file),
                        path: ""
                    }
                })
            ]);
            setBlobs([
                ...blobs,
                ...acceptedFiles.map(file => {
                    return file
                })
            ]);
        }
    });

    const removeFile = (index: number) => {
        const newFiles = previews.filter((_, x) => x !== index);
        setPreviews(newFiles)
    }

    async function onSubmit() {
        if (!report?.id) return;

        if (blobs.length === 0) {
            await updateDoc(doc(db, "Reports", report.id), {
                images: previews
            });
            goNext();
            return;
        }

        try {
            setLoading(true);
            const tid = toast.loading("Uploading images...", {
                duration: Infinity
            });

            const storagePath = `reports/${id}`;
            const imgs = await uploadBlobs(storagePath, blobs);
            toast.loading("Analysing with Reportable AI...", {
                duration: Infinity,
                id: tid
            });

            const res = await postApi("/ai/check-images", {
                imgs: imgs,
                reportId: id
            });
            if (res.data.success) {
                toast.success("Images analysed", {
                    id: tid,
                    duration: 4000
                });
                goNext();
            } else {
                toast.error("An error occurred", {
                    id: tid,
                    duration: 8000,
                    description: res.data.message
                });
            }
            
        } catch(e) {
            console.log(e);
            toast.error("An error occurred", {
                description: "Please try uploading again"
            });
        } finally {
            setLoading(false);
        }
        
    }

    const loadImages = async(paths: string[]) => {
        setLoading(true);
        const downloadurls = [];
        for await (const path of paths) {
            const url = await getDownloadURL(ref(getStorage(app), path));
            downloadurls.push({
                url: url,
                path: ""
            });
        }
        setPreviews(downloadurls);
        setLoading(false);
    }

    useEffect(() => {
        if (report?.images) {
            setPreviews(report?.images);
        }
    }, [report?.images])

    return <Card className="w-full rounded-none">
        <CardHeader>
            <CardTitle>Building Images</CardTitle>
            <CardDescription>Upload up to 20 images of building.</CardDescription>
        </CardHeader>
        <CardContent>
            <div className="flex flex-col space-y-4">
                <Input 
                    name="images"
                    id="images"
                    type="file"
                    multiple
                    disabled={loading}
                    {...getInputProps()}
                />
                <Badge className="w-fit" variant="outline">
                    <InfoCircledIcon className="mr-2 w-4 h-5" />Up to {Math.max(0, 20-previews.length)} more images allowed
                </Badge>
                <Button
                    variant="secondary"
                    className="w-fit"
                    onClick={open}
                    disabled={loading}
                >
                    {loading ? <>Loading images <Icons.spinner className="animate-spin w-4 h-4 ml-2" /></> : "Add Images"}
                </Button>
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 py-3">
                    {previews.map((img, x) => (
                        <div
                            key={`preview_${x}`}
                            className="relative w-40 min-w-40 h-40 bg-contain bg-no-repeat bg-center m-3 p-2 border"
                            style={{
                                backgroundImage: `url(${img.url})`,
                            }}
                        >
                            <Button
                                variant="ghost"
                                className="p-0 h-4 w-4 absolute -top-2 -right-2 bg-muted"
                                disabled={loading}
                                onClick={(e) => {
                                    e.preventDefault();
                                    removeFile(x);
                                }}
                            ><XIcon className="w-4 h-4" /></Button>
                        </div>
                    ))}
                </div>           
                <div className="flex space-x-3 space-y-3">
                    
                </div>
            </div>
        </CardContent>
        <CardFooter>
            <FormActions goNext={() => onSubmit()} />
        </CardFooter>
    </Card>
}