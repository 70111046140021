import { Outlet } from 'react-router-dom';

import logo from '@/assets/images/logo-light.png';
import { Card, CardContent } from '@/components/ui/card';

export const AuthScreen = () => {
    return (
        <div className="overflow-hidden min-h-screen bg-background shadow-md md:shadow-xl">
            <div className="container relative min-h-screen flex-col items-center justify-center grid lg:max-w-none lg:grid-cols-2 px-0">
                <div className="relative h-full flex-col justify-between bg-muted p-10 lg:p-20 text-white flex gap-6">
                    <div className="absolute inset-0 bg-secondary-dark" />
                    <div className="relative flex items-center text-lg font-medium">
                        <img src={logo} className='w-48' />
                    </div>
                    <div className='z-20'>
                        <Card>
                            <CardContent className='rounded-md p-4 bg-secondary-foreground'>
                                <iframe 
                                    width="1280" 
                                    height="720" 
                                    className='w-full h-auto aspect-video'
                                    src="https://www.youtube.com/embed/gjpXUD8Vz6I" 
                                    title="High-Converting Product Explainer Video for Surfe (ex-Leadjet)" 
                                    frameBorder="0" 
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen
                                ></iframe>
                            </CardContent>
                        </Card>
                    </div>
                    <div className="relative">
                        <blockquote className="space-y-2">
                            <p className="text-md lg:text-lg">
                                Experience the power of Reports: 
                                The reporting software that streamlines on-site and desktop assessments, saving your company valuable time and resources.
                            </p>
                            <footer className="text-sm">© Reportable {new Date().getFullYear()}</footer>
                        </blockquote>
                    </div>
                </div>
                <div className="p-8">
                    <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    );
};
