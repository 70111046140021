import { ReportType } from "@/screens/reports/data/schema";
import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const randomString = (length: number) => {
  const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = ' ';
  const charactersLength = characters.length;
  for ( let i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result.trim();
}

export const reportUrl = (reportTemplate: string | undefined, id: string) => {
  return `/v1/reports/${reportTemplate === "makesafe" ? `makesafe/${id}` : id}/client`;
}