"use client";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { useSiteState } from "@/providers/state-provider";
import { CoinsIcon } from "lucide-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useCallback, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useAuth } from "@/providers/auth-provider";
import { toast } from "sonner";
import { appCheck } from "@/lib/firebase";
import { getToken } from "firebase/app-check";

const fetch = require("node-fetch");

type CheckoutResponse = {
  checkout_url: string;
};

const FormSchema = z
  .object({
    package: z.enum(["10", "50", "100", "custom", "1"], {
      required_error: "You need to select a package or enter a custom amount.",
    }),
    customAmount: z.string().optional(),
  })
  .refine((input) => {
    const camount = Number(input.customAmount);
    console.log(input.package, camount, camount < 1);
    if (input.package === "custom" && (!input.customAmount || isNaN(camount)))
      return false;
    if (camount < 1) return false;
    return true;
  }, "Please enter a custom amount greater than 0.");

const CreditModal = () => {
  const { userData, user } = useAuth();
  const { showCreditModal, setShowCreditModal } = useSiteState();

  const [loading, setLoading] = useState(false);
  const [isCustom, setIsCustom] = useState(false);

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      package: "10",
      customAmount: "25",
    },
  });

  const onSubmit = useCallback(
    async (data: z.infer<typeof FormSchema>) => {
      try {
        setLoading(true);
        // stripe redirect

        const quantity =
          data.package === "custom" ? data.customAmount : data.package;
        const token = (await getToken(appCheck)).token;

        if (data.package === "1") {
          // Create Stripe Subscription
          console.log("monthly subscription 1")
          console.log("monthly subscription 2", 1)
          const res = await fetch(
            `${process.env.REACT_APP_API_URL}/create-subscription?email=${userData?.email}&quantity=${1}&userId=${user?.uid}`,
            {
              headers: {
                "X-Firebase-AppCheck": token,
              },
            });

          const ress = (await res.json()) as CheckoutResponse;

          window.location.href = ress.checkout_url;

        } else {
          const res = await fetch(`${process.env.REACT_APP_API_URL}/create-checkout?email=${userData?.email}&quantity=${quantity}&userId=${user?.uid}`,
            {
              headers: {
                "X-Firebase-AppCheck": token,
              },
            }
          );
          const ress = (await res.json()) as CheckoutResponse;

          window.location.href = ress.checkout_url;
        }

      } catch (e) {
        console.log(e);
        toast.error("An error occurred, please try again.");
        setLoading(false);
      }
    },
    [userData, user]
  );

  return (
    <AlertDialog
      open={showCreditModal}
      onOpenChange={(open) => setShowCreditModal(open)}
    >
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Purchase Credits</AlertDialogTitle>
          <AlertDialogDescription className="transition-all duration-300">
            1 credit can generate 1 report. No expiry date.
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)}>
                <div className="py-4 space-y-6">
                  <FormField
                    control={form.control}
                    name="package"
                    render={({ field }) => (
                      <FormItem className="space-y-3">
                        <FormControl>
                          <RadioGroup
                            className="grid grid-cols-3 gap-4"
                            onValueChange={(val) => {
                              field.onChange(val);
                              setIsCustom(val === "custom");
                            }}
                            value={field.value}
                          >
                            <div>
                              <RadioGroupItem
                                value="10"
                                id="amount_10"
                                className="peer sr-only"
                              />
                              <Label
                                htmlFor="amount_10"
                                className="flex flex-col items-center justify-between rounded-md border-2 border-muted bg-popover p-4 hover:bg-accent peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary"
                              >
                                <CoinsIcon className="mb-3 h-6 w-6" />
                                10 Reports
                              </Label>
                            </div>
                            <div>
                              <RadioGroupItem
                                value="50"
                                id="amount_50"
                                className="peer sr-only"
                              />
                              <Label
                                htmlFor="amount_50"
                                className="flex flex-col items-center justify-between rounded-md border-2 border-muted bg-popover p-4 hover:bg-accent peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary"
                              >
                                <CoinsIcon className="mb-3 h-6 w-6" />
                                50 Reports
                              </Label>
                            </div>
                            <div>
                              <RadioGroupItem
                                value="100"
                                id="amount_100"
                                className="peer sr-only"
                              />
                              <Label
                                htmlFor="amount_100"
                                className="flex flex-col items-center justify-between rounded-md border-2 border-muted bg-popover p-4 hover:bg-accent peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary"
                              >
                                <CoinsIcon className="mb-3 h-6 w-6" />
                                100 Reports
                              </Label>
                            </div>

                            <div className="flex items-center space-x-2 mt-3">
                              <RadioGroupItem value="custom" id="custom" />
                              <Label htmlFor="custom">Custom Amount</Label>
                            </div>


                            <div className="flex items-center space-x-2 mt-3">
                              <RadioGroupItem value="1" id="monthlySubscription" />
                              <Label htmlFor="monthlySubscription">$800/month Unlimited Reports</Label>
                            </div>
                          </RadioGroup>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <AnimatePresence mode="wait">
                    {isCustom && (
                      <motion.div
                        className="flex flex-col space-y-3"
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "100%" }}
                        transition={{ duration: 0.5 }}
                      >
                        <FormField
                          control={form.control}
                          name="customAmount"
                          render={({ field }) => (
                            <FormItem className="space-y-3">
                              <FormControl>
                                <Input
                                  type="number"
                                  {...field}
                                  min={1}
                                  max={99999999}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </form>
            </Form>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel disabled={loading}>Cancel</AlertDialogCancel>
          <AlertDialogAction
            disabled={loading}
            onClick={form.handleSubmit(onSubmit)}
          >
            Continue
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default CreditModal;
