"use client"

import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { ZodTypeAny, z } from "zod"

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@/components/ui/form"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { FormActions } from "@/screens/reports/form/form-actions"
import { useFormWizard } from "@/screens/reports/form"
import { Input } from "@/components/ui/input"
import { cn } from "@/lib/utils"
import { CheckCircle } from "lucide-react"
import { Separator } from "@/components/ui/separator"
import { Textarea } from "@/components/ui/textarea"
import { doc, updateDoc } from "firebase/firestore"
import { db } from "@/lib/firebase"
import { QuestionType, reportTypeQuestions } from "@/screens/reports/data/data"
import { questionTypeSchema } from "@/lib/zod"
import { ReportType } from "@/screens/reports/data/schema"

export const CheckMark = ({ val, className }: { val: any, className?: string }) => (
  <CheckCircle className={cn("absolute w-4 h-4 right-10 text-emerald-600", val ? "" : "hidden", className ? className : "")} />
)

export function MakeSafeBaseQuestionsForm() {

  const { report, loading, setLoading, goNext } = useFormWizard();

  const baseSchema = z.object({
    inspection_date: z
      .string().min(1, {
        message: "Please enter the inspection date",
      }),
    inspection_time: z
      .string().min(1, {
        message: "Please enter the inspection time",
      }),
    inspection_by: z
      .string().min(1, {
        message: "Please enter the name of the inspector",
      }),
    weather: z
      .string().min(1, {
        message: "Please enter the weather at the time of inspection",
      }),
    trades_that_attended: z
      .string().min(1, {
        message: "Please enter the number that attended",
      }),
    trades_on_site: z
      .string().min(1, {
        message: "Please enter the length of time",
      }),
    materials_used: z
      .string().min(1, {
        message: "Please enter the material's used",
      }),
    po_instructions: z
      .string().min(1, {
        message: "Please enter the PO instructions",
      }),
    cause: z
      .string().min(1, {
        message: "Please enter the cause",
      }),
    damage: z
      .string().min(1, {
        message: "Please enter the damage",
      }),
    works_completed: z
      .string().min(1, {
        message: "Please enter the works completed",
      }),
    works_remaining: z
      .string().min(1, {
        message: "Please enter the works remaining",
      }),
  });

  type FormValues = z.infer<typeof baseSchema>;

  // This can come from your database or API.
  const defaultValues: Partial<FormValues> = {
    inspection_date: report?.inspection_date || "",
    inspection_time: report?.inspection_time || "",
    inspection_by: report?.inspection_by || "",
    trades_that_attended: report?.trades_that_attended || "",
    trades_on_site: report?.trades_on_site || "",
    materials_used: report?.materials_used || "",
    po_instructions: report?.po_instructions || "",
    cause: report?.cause || "",
    damage: report?.damage || "",
    works_completed: report?.works_completed || "",
    works_remaining: report?.works_remaining || "",
    weather: report?.weather || "",
  }

  const form = useForm<FormValues>({
    resolver: zodResolver(baseSchema),
    defaultValues,
    mode: "onChange",
  });

  async function onSubmit(data: FormValues) {
    if (!report?.id) {
      return;
    }
    setLoading(true);

    await updateDoc(doc(db, "Reports", report?.id), {
      ...data
    });
    setLoading(false);
    goNext();
  }

  return (
    <Card className="w-full rounded-none">
      <CardHeader>
        <CardTitle>Base Assessment</CardTitle>
        <CardDescription>Some basic information about the building.</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col space-y-4">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="grid grid-cols-2 gap-4">
              <div className="col-span-2 lg:col-span-1">
                <FormField
                  control={form.control}
                  name="inspection_date"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Inspection Date</FormLabel>
                      <FormControl>
                        <div className="relative flex items-center">
                          <Input type="date" placeholder="e.g. 5" {...field} className={cn(field.value ? "border-2 border-emerald-600" : "")} />
                          <CheckMark val={field.value} />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="col-span-2 lg:col-span-1">
                <FormField
                  control={form.control}
                  name="inspection_time"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Inspection Time</FormLabel>
                      <FormControl>
                        <div className="relative flex items-center">
                          <Input type="time" placeholder="e.g. 14:00" {...field} className={cn(field.value ? "border-2 border-emerald-600" : "")} />
                          <CheckMark val={field.value} />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="col-span-2 lg:col-span-1">
                <FormField
                  control={form.control}
                  name="inspection_by"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Inspected By</FormLabel>
                      <FormControl>
                        <div className="relative flex items-center">
                          <Input type="text" {...field} className={cn(field.value ? "border-2 border-emerald-600" : "")} />
                          <CheckMark val={field.value} />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="col-span-2 lg:col-span-1">
                <FormField
                  control={form.control}
                  name="weather"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Weather at Inspection Time</FormLabel>
                      <FormControl>
                        <div className="relative flex items-center">
                          <Input type="text" placeholder="e.g. Clear" {...field} className={cn(field.value ? "border-2 border-emerald-600" : "")} />
                          <CheckMark val={field.value} />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <Separator className="col-span-2 my-4" />
              <div className="col-span-2">
                <FormField
                  control={form.control}
                  name="trades_that_attended"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Number of trades that attended</FormLabel>
                      <FormControl>
                        <div className="relative flex items-center">
                          <Input type="number" placeholder="e.g. 3" {...field} className={cn(field.value && !isNaN(Number(field.value)) ? "border-2 border-emerald-600" : "")} />
                          <CheckMark val={field.value} />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="col-span-2 lg:col-span-1">
                <FormField
                  control={form.control}
                  name="trades_on_site"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>How long were trades on site</FormLabel>
                      <FormControl>
                        <div className="relative flex items-center">
                          <Input type="text" {...field} className={cn(field.value ? "border-2 border-emerald-600" : "")} />
                          <CheckMark val={field.value} />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="col-span-2 lg:col-span-1">
                <FormField
                  control={form.control}
                  name="materials_used"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Materials Used</FormLabel>
                      <FormControl>
                        <div className="relative flex items-center">
                          <Input type="text" {...field} className={cn(field.value ? "border-2 border-emerald-600" : "")} />
                          <CheckMark val={field.value} />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="col-span-2 lg:col-span-1">
                <FormField
                  control={form.control}
                  name="po_instructions"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>PO Instructions</FormLabel>
                      <FormControl>
                        <div className="relative flex items-center">
                          <Input type="text" {...field} className={cn(field.value ? "border-2 border-emerald-600" : "")} />
                          <CheckMark val={field.value} />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="col-span-2 lg:col-span-1">
                <FormField
                  control={form.control}
                  name="cause"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Cause</FormLabel>
                      <FormControl>
                        <div className="relative flex items-center">
                          <Input type="text" {...field} className={cn(field.value ? "border-2 border-emerald-600" : "")} />
                          <CheckMark val={field.value} />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="col-span-2 lg:col-span-1">
                <FormField
                  control={form.control}
                  name="damage"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Damage</FormLabel>
                      <FormControl>
                        <div className="relative flex items-center">
                          <Input type="text" {...field} className={cn(field.value ? "border-2 border-emerald-600" : "")} />
                          <CheckMark val={field.value} />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="col-span-2">
                <FormField
                  control={form.control}
                  name="works_completed"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Works Completed</FormLabel>
                      <FormControl>
                        <div className="relative flex items-center">
                          <Textarea rows={3} {...field} className={cn(field.value ? "border-2 border-emerald-600" : "")} />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="col-span-2">
                <FormField
                  control={form.control}
                  name="works_remaining"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Works Remaining</FormLabel>
                      <FormControl>
                        <div className="relative flex items-center">
                          <Textarea rows={3} {...field} className={cn(field.value ? "border-2 border-emerald-600" : "")} />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </form>
          </Form>
        </div>
      </CardContent>
      <CardFooter>
        <FormActions goNext={form.handleSubmit(onSubmit)} />
      </CardFooter>
    </Card>
  )
}